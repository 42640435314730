<template>
  <div class="relative flex min-h-screen flex-col">
    <header
      id="header"
      class="relative top-0 z-40 flex h-16 w-full flex-row items-center justify-between bg-bg-dark px-4 py-3 text-white sm:fixed md:h-[72px] md:px-3 lg:px-10"
    >
      <NuxtLink class="relative z-10" to="#">
        <ClientOnly>
          <Logo class="hidden lg:block" />
          <img class="w-8 lg:hidden" src="/assets/images/small-logo.png" alt="logo" />
        </ClientOnly>
      </NuxtLink>
      <h3 class="text-caption-2 md:text-h3 text-black-30">{{ title }}</h3>
    </header>
    <main class="flex flex-1 flex-col pt-4 md:pt-[72px]">
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import Logo from '@/assets/images/logo.svg?component'

const bus = useEventBus<string>('title')
const title = ref()
bus.on((e: any) => {
  title.value = e
})
</script>

<style scoped></style>
